import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import axios from 'axios';

const isProductionCode = process.env.NODE_ENV === 'production';
const fallbackLanguage = 'en';

const projectToken = "a9ed9a1c482c4096a3df18f0fefcad44";
const apiKey = "368093877dC2de4f2178b51EC8040Ab38DbeA0127D80c100";

const apiBaseUrl = "https://api.simplelocalize.io/api";
const cdnBaseUrl = "https://cdn.simplelocalize.io";
const environment = "_latest"; 
const loadPath = `${cdnBaseUrl}/${projectToken}/${environment}/{{lng}}`;

const configuration = {
    headers: {
        'X-SimpleLocalize-Token': apiKey
    }
};


const missing = [];

const createTranslationKeys = async (requestBody) => axios.post(`${apiBaseUrl}/v1/translation-keys/bulk`, requestBody, configuration);
const updateTranslations = async (requestBody) => axios.patch(`${apiBaseUrl}/v2/translations/bulk`, requestBody, configuration);

const saveMissing = async () => {
    if (missing.length === 0 || isProductionCode) {
        return;
    }
    console.info(`Saving ${missing.length} missing translation keys`);

    const translationKeys = missing.map((element) => ({
        key: element.translationKey,
        namespace: element.namespace,
    }));

    await createTranslationKeys({ translationKeys })
        .catch((error) => console.error(`Error during creating translation keys: ${error}`));

    const translations = missing.map((element) => ({
        key: element.translationKey,
        namespace: element.namespace,
        language: element.language,
        text: element.fallbackValue,
    }));
    await updateTranslations({ translations })
        .catch((error) => console.error(`Error during updating translations: ${error}`));
    missing.length = 0;
};

// Control how often missing translation keys are sent to SimpleLocalize
setInterval(async () => {
    await saveMissing();
}, 30_000); // 30 seconds

// Function to get language from the URL
const getLanguageFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    const lang = params.get('lang');
    return lang && ['en', 'es', 'zh'].includes(lang) ? lang : fallbackLanguage;
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng:  getLanguageFromUrl(), // Set language based on URL
        fallbackLng: fallbackLanguage,
        backend: {
            loadPath: `${cdnBaseUrl}/${projectToken}/${environment}/{{lng}}`,
        },
        saveMissing: !isProductionCode, // Save missing keys only in development mode
       
        missingKeyHandler: async (languages, namespace, translationKey, fallbackValue) => {
            console.debug(`[${namespace}][${translationKey}] not available in Translation Hosting`);
            missing.push({
                translationKey: translationKey,
                namespace: namespace ?? "",
                language: languages[0] ?? fallbackLanguage,
                fallbackValue: fallbackValue ?? ""
            });
        },
        interpolation: {
            escapeValue: false, // React already prevents XSS
        },
        debug: true, // Enable debug to see what's happening
    });

export default i18n;

